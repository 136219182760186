import React from 'react'
import Script from 'next/script'
import { FEATURE_TOGGLES } from '@/services/Configuration'

const isCSChatEnabled = !!FEATURE_TOGGLES?.isCSChatEnabled

const CSChat: React.FC = () => {
  if (!isCSChatEnabled) {
    return null
  }
  return (
    <Script
      strategy="afterInteractive"
      data-testid="cscChatScript"
      type="text/javascript"
      id="cscChatScript"
      dangerouslySetInnerHTML={{
        __html: `(function(w, d, x, id){
          s=d.createElement('script');
          s.src='https://db08fjupg2abb.cloudfront.net/amazon-connect-chat-interface-client.js';
          s.async=1;
          s.id=id;
          d.getElementsByTagName('head')[0].appendChild(s);
          w[x] =  w[x] || function() { (w[x].ac = w[x].ac || []).push(arguments) };
        })(window, document, 'amazon_connect', '510771d9-bda7-455d-9251-ceb3620ede34');
        amazon_connect('styles', { openChat: { color: '#ffffff', backgroundColor: '#3B5F58'}, closeChat: { color: '#ffffff', backgroundColor: '#3B5F58'} });
        amazon_connect('snippetId', 'QVFJREFIZ2JTNzdvNlZBdTFZSVJkT0xad3RDb08wM2NZamVYWno2UE4rbHpYL2JuRlFINUo5R29oYllEMHoreVVDSVpqelU2QUFBQWJqQnNCZ2txaGtpRzl3MEJCd2FnWHpCZEFnRUFNRmdHQ1NxR1NJYjNEUUVIQVRBZUJnbGdoa2dCWlFNRUFTNHdFUVFNWGR5K0g0ZGNRS3hVeGVCaEFnRVFnQ3ZmbTVRblhMV1UyYU1ERUdkYUZwa3FFQ25nWEowMkNkRGhxaWh2SHBwM3RpSG5jeXRmMUhtQ3l4ZXQ6OjF3cGE4cWlid1F3eFRLcSszR2d6cEVwdzJheFhLMGtkUVcwUGNBeWdOL2VFTEUxLzFrQmpxZ1pvN0RtZzViZDhlNmlvbFRjeExBcnFsbUw1Tm9QdnQ3bVc0S2RtaG5hcmw4N2lJRWxpWHhtVzRJT2JveG1nUGh4aU9kOCt2b1NVYklzcUxVTktMNjN3ZSs4UG9TS0IzTEtXeDhpaEI5dz0=');
        amazon_connect('supportedMessagingContentTypes', [ 'text/plain', 'text/markdown' ]);`,
      }}
    />
  )
}

export default CSChat
